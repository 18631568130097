<template>
  <div class="home">
    <div class="auth-form">
      <div class="flex">
        <div class="auth-empty">
          <auth-left></auth-left>
        </div>
        <div class="form">
           <div class="form-controller">
              <form @submit.prevent="submit" class="form-control__content">
                <h2 class="center">Sign in</h2>
                  <b-field class="mt-5" label="Email address" :label-position="'on-border'">
                      <b-input type="email" v-model="form.email" size="is-medium" required></b-input>
                  </b-field>
                  <br>
                    <b-field label="Password" :label-position="'on-border'">
                      <b-input type="password" size="is-medium" v-model="form.password" required></b-input>
                  </b-field>
                  <div>
                    <small>
                      <router-link to="/reset-password">Forgot password?</router-link>
                    </small>
                  </div>
                   <br>
                  <b-button native-type="submit" type="is-primary" size="is-medium"  expanded>Sign in</b-button>
                  <p class="center mt-5">Don't have an account? <router-link to="/">Sign up</router-link></p>
              </form>
           </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from 'firebase'
import AuthLeft from '@/components/AuthLeft.vue';

export default {
  components: { AuthLeft },
  name: 'Home',
  data: () => ({
    form: {},
  }),
  methods: {
    submit(){
        firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION)
        .then(() => {
          return firebase.auth().signInWithEmailAndPassword(this.form.email, this.form.password)
             .then((userCredential) => {
                const user = userCredential.user;
                if(user) return this.$router.push('/m/app')
            }).catch((error) => {
               this.hasError(error)
            })
        })
        .catch((error) => {
         this.hasError(error)
        });
    },
    hasError(error){
        this.$buefy.toast.open({
            message: error.message,
            type: 'is-warning'
        })
    }
  }
}
</script>


<style lang="scss" scoped>
@import '../../assets/scss/style.scss';





</style>
