import { render, staticRenderFns } from "./PartnerLogin.vue?vue&type=template&id=f5e45b10&scoped=true&"
import script from "./PartnerLogin.vue?vue&type=script&lang=js&"
export * from "./PartnerLogin.vue?vue&type=script&lang=js&"
import style0 from "./PartnerLogin.vue?vue&type=style&index=0&id=f5e45b10&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f5e45b10",
  null
  
)

export default component.exports